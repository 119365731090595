import { AxiosPromise, AxiosRequestConfig } from 'axios'
import {
  ILogin,
  ICaixasTotalizador,
  IResponseLogin,
  ILoja,
  IQueryCaixas,
  IFormaPagamentoTotalizador,
  IQueryListaOperacoes,
  IResponseOperacoesCaixa,
  IResponseProdutos,
  IFilters,
  ITotalizadorCaixa,
  IRamosAtividade,
  IResponseGetCaixasAgrupadosECaixas,
  IQtdDiasFuncionamento,
  IResponseTotalsByTypeOperations,
  IResponseGetOperacoesNaoRecebidas,
  IItemPedido,
  IConfiguracoesForcaVendas,
  IPedido,
  TotaisPorStatusOperacaoNaoRecebida,
  TotaisCartoes,
  IDataAndLoja,
  TotaisCartoesPorAdministradora,
  TotaisCartoesPorBandeiras,
  TotaisCartoesPorLojas,
  TotaisCartoesPorModalidades,
  DetalhesCartoes,
  IRequestDetalhesCartoes,
  TotaisDetalhamentoCartoes,
  OptionsBandeira,
  OptionsAdministradora,
  RakingTaxasCartoes,
  TotaisDuplicatasAReceber,
  Duplicatas,
  IRequestDefault,
  Cheques,
  ContasPagar,
  operacoesComerciais,
  dadosGraficoOperacoesComerciais,
  IQueryOperacoesComerciais,
  pedidosDeCompras,
  fechamentoContas,
  ResponseMovimentoVendas,
  IDatas,
  ResponseTotaisLojas,
  ResponseTotaisFormaPagamento,
  IRequestTopVendas,
  TopVendas,
  cadastroDeProdutos,
  notasFiscais,
  totaisNotasFiscais,
  cuponsFiscaisEletronicosEmitidos,
  totaisCuponsFiscaisEletronicosEmitidos,
  clientesConstantes,
  clientesEsporadicos,
  unidadeDeNegocios,
  centroDeCustos,
  representantes,
  transportadoras,
  fornecedores,
  parceirosIndicantes,
  marketplaces,
  ramosDeAtividade,
  condicoesPagamento,
  administradoraCartoes,
  classificacaoReceitasDespesas,
  planoDeContasEmGeral,
  tabelaDespesas,
  tabelaReceitas,
  regioes,
  cidades,
  paises,
  cadastroDeDolares,
  feriados,
  ComparativoVendas,
  IRequestComparativoVendas,
  bandeirasDeCartoes,
  contasDeMovimentacaoFinanceira,
  orcamentosNaoConvertidos,
  followupsDeOperacoes,
  IRequestGetClientesByHistoricoDeCompra,
  ClientesByHistoricoDeCompra,
  ComprasUltimoAnoCliente,
  fechamentosDeCaixas,
  IQueryFechamentosCaixa,
  dadosGraficoFechamentosCaixa,
  dadosGraficoAbatimentoDeContas,
  grupoDeProdutos,
  fabricantes,
  departamentos,
  representantesFilter,
  fornecedoresFilter,
  abatimentoDeContas,
  EmailById,
  IQueryAbatimentoDeContas,
  lotesDeRecebimento,
  TotaisAbatimentosDeContas,
  embalagensDeProdutos,
  gruposDeProdutos,
  notasDeEntrada,
  categoriasDeProdutos,
  departamentosDeProdutos,
  transferenciasDeMercadorias,
  cotacoesDeCompras,
  gestaoDeEntregas,
  IResTotaisEquipesRanking,
  IResTotaisVendedores,
  IReqWorkinAndWorkedDays,
  IResWorkinAndWorkedDays,
  IResCarteiraCliente,
  IResCarteiraClientesByEquipe,
  IResEquipes,
} from '../@types'
import xhr from './xhr'

const setDynamicHeader = (token: string): AxiosRequestConfig => ({
  headers: {
    'Content-Type': 'application/json',
    Authorization: token,
  },
  timeout: 10000,
})

/**
 * Auth
 */
const login = (params: ILogin): AxiosPromise<IResponseLogin> =>
  xhr.post('/auth/login', params)

/*
 * Configuracao
 */

const getConfiguracaoCnx = (): AxiosPromise<{ cfivalcfg: 'S' | 'N' | 'M' }> =>
  xhr.get('/configuracoes/cnx')

const getConfiguracoesForcaVendasLoja =
  (): AxiosPromise<IConfiguracoesForcaVendas> =>
    xhr.get('/configuracoes/forca-vendas')

/**
 * Caixas
 */

const getOperacoesNaoRecebidas = (
  params: IFilters,
): AxiosPromise<IResponseGetOperacoesNaoRecebidas> =>
  xhr.get('/caixa/operacoes-nao-recebidas', { params })

const getCaixasAgrupadosECaixas = (
  params: IQueryCaixas,
): AxiosPromise<IResponseGetCaixasAgrupadosECaixas> =>
  xhr.get('/caixas/totalizador/por-data', { params })

const getTotalizersCashiers = (
  params: IQueryCaixas,
): AxiosPromise<ITotalizadorCaixa> => xhr.get('/caixas/totalizador', { params })

const getValuesMasterCashiers = (
  params: IQueryCaixas,
): AxiosPromise<ICaixasTotalizador[]> =>
  xhr.get('/caixas/totalizador/dia/master', { params })

const getValuesStoreCashiers = (
  params: IQueryCaixas,
): AxiosPromise<ICaixasTotalizador[]> =>
  xhr.get('/caixas/totalizador/dia/loja', { params })

const getValoresCaixasPorFormaPagamento = (
  params: IQueryCaixas,
): AxiosPromise<IFormaPagamentoTotalizador> =>
  xhr.get('/caixas/totalizador/forma-pagamento', { params })

const getValoresCaixasPorFormaPagamentoECodigo = (
  codigo: string,
): AxiosPromise<IFormaPagamentoTotalizador> =>
  xhr.get(`/caixas/totalizador/forma-pagamento/${codigo}`)

const getDiasFuncionamento = (
  params: IQueryCaixas,
): AxiosPromise<IQtdDiasFuncionamento> =>
  xhr.get('/caixas/dias-funcionamento', { params })

const getOperacoesCaixa = (
  params: IQueryListaOperacoes & { countDisable: boolean },
): AxiosPromise<IResponseOperacoesCaixa> =>
  xhr.get('/caixas/operacoes', { params })

const totaisPorTipoOperacao = (
  params: IQueryCaixas,
): AxiosPromise<IResponseTotalsByTypeOperations[]> =>
  xhr.get('/caixas/totalizador/tipo-operacao', { params })

const totaisPorStatusOperacaoNaoRecebida = (
  params: IFilters,
): AxiosPromise<TotaisPorStatusOperacaoNaoRecebida | null> =>
  xhr.get('/caixas/totalizador/operacao-recebida/status-operacao', { params })

const getFechamentosDeCaixa = (
  params: IRequestDefault,
): AxiosPromise<{
  fechamentosCaixa: fechamentosDeCaixas[]
  totalPaginas: number
}> => xhr.get('/caixas/fechamentos-de-caixas', { params })

const FechamentosCaixaTotais = (
  params: IDataAndLoja,
): AxiosPromise<TotaisDuplicatasAReceber[]> =>
  xhr.get('/caixas/fechamentos-de-caixas/totais', { params })

const getGraficoFechamentosCaixa = (
  params: IQueryFechamentosCaixa,
): AxiosPromise<dadosGraficoFechamentosCaixa> =>
  xhr.get('/caixas/fechamentos-de-caixas/grafico', { params })

const getAbatimentoDeContas = (
  params: IRequestDefault,
): AxiosPromise<{
  abatimentoDeContas: abatimentoDeContas[]
  totalPaginas: number
}> => xhr.get('/caixas/abatimentos-de-contas', { params })

const getGraficoAbatimentoDeContas = (
  params: IQueryAbatimentoDeContas,
): AxiosPromise<dadosGraficoAbatimentoDeContas> =>
  xhr.get('/caixas/abatimentos-de-contas/grafico', { params })

const abatimentoDeContasTotais = (
  params: IDataAndLoja,
): AxiosPromise<TotaisAbatimentosDeContas[]> =>
  xhr.get('/caixas/abatimentos-de-contas/totais', { params })

const getLotesDeRecebimento = (
  params: IRequestDefault,
): AxiosPromise<{
  lotesDeRecebimento: lotesDeRecebimento[]
  totalPaginas: number
}> => xhr.get('/caixas/lotes-de-recebimento', { params })

/**
 * Lojas
 */
const getLojas = (): AxiosPromise<ILoja[]> => xhr.get('/lojas')

const getLojasLogin = (token: string): AxiosPromise<ILoja[]> =>
  xhr.get('/lojas', setDynamicHeader(`Bearer ${token}`))

/**
 * Produtos
 */
const getProdutos = (params: IFilters): AxiosPromise<IResponseProdutos> =>
  xhr.get('/produtos', { params })

/**
 * Ramos de atividade
 */
const buscarRamosAtividade = (): AxiosPromise<IRamosAtividade[]> =>
  xhr.get('/ramos-atividade')

const editarRamoAtividade = (
  codigo: number,
  params: IRamosAtividade,
): AxiosPromise<any> => xhr.put(`/ramos-atividade/${codigo}`, params)

const adicionarRamoAtividade = (ramo: IRamosAtividade): AxiosPromise<number> =>
  xhr.post('/ramos-atividade/', ramo)

const excluirRamoAtividade = (codigo: number): AxiosPromise<any> =>
  xhr.delete(`/ramos-atividade/${codigo}`)

/*
 * Pedidos
 */

const buscarPedidoPeloCodigo = (codigo: string): AxiosPromise<IPedido> =>
  xhr.get(`/pedido/codigo/${codigo}`)

const getItensPedido = (
  codigoPedido: number,
  params: IQueryCaixas,
): AxiosPromise<IItemPedido[]> =>
  xhr.get(`/pedido/itens/${codigoPedido}`, { params })

const getItensPedidoFCO = (
  codigoPedido: number,
  params: IQueryCaixas,
): AxiosPromise<IItemPedido[]> =>
  xhr.get(`/fco/pedido/itens/${codigoPedido}`, { params })

const getPedidoEItens = (
  codigoPedido: string,
  params: IQueryCaixas,
): AxiosPromise<IPedido> =>
  xhr.get(`/pedido-e-items/${codigoPedido}`, { params })

const getPedidoEItensFCO = (
  codigoPedido: string,
  params: IQueryCaixas,
): AxiosPromise<IPedido> =>
  xhr.get(`/fco/pedido-e-items/${codigoPedido}`, { params })

/*
 * Cartoes
 */

const getTotaisCartoes = (
  params: IDataAndLoja,
): AxiosPromise<TotaisCartoes | null> => xhr.get('/cartoes/totais', { params })

const getTotaisCartoesPorAdministradora = (
  params: IDataAndLoja,
): AxiosPromise<TotaisCartoesPorAdministradora[]> =>
  xhr.get('/cartoes/totais/por-administradoras', { params })

const getTotaisCartoesPorBandeiras = (
  params: IDataAndLoja,
): AxiosPromise<TotaisCartoesPorBandeiras[]> =>
  xhr.get('/cartoes/totais/por-bandeiras', { params })

const getTotaisCartoesPorLojas = (
  params: IDataAndLoja,
): AxiosPromise<TotaisCartoesPorLojas[]> =>
  xhr.get('/cartoes/totais/por-lojas', { params })

const getTotaisCartoesPorModalidades = (
  params: IDataAndLoja,
): AxiosPromise<TotaisCartoesPorModalidades[]> =>
  xhr.get('/cartoes/totais/por-modalidades', { params })

const getDetalhesCartoes = (
  params: IRequestDetalhesCartoes,
): AxiosPromise<DetalhesCartoes> => xhr.get('/cartoes/detalhamento', { params })

const getTotaisDetalhamentoCartoes = (
  params: IDataAndLoja,
): AxiosPromise<TotaisDetalhamentoCartoes> =>
  xhr.get('/cartoes/totais/detalhamento', { params })

const getOptionsBandeiraCartoes = (
  params: IDataAndLoja,
): AxiosPromise<OptionsBandeira[]> =>
  xhr.get('/cartoes/options-select/bandeira', { params })

const getOptionsAdministradoraCartoes = (
  params: IDataAndLoja,
): AxiosPromise<OptionsAdministradora[]> =>
  xhr.get('/cartoes/options-select/administradora', { params })

const getRankingTaxasCartoes = (
  params: IDataAndLoja,
): AxiosPromise<RakingTaxasCartoes[]> =>
  xhr.get('/cartoes/ranking-taxas', { params })

/**
 * Financeiro
 */

const aReceberTotaisDuplicatas = (
  params: IDataAndLoja,
): AxiosPromise<TotaisDuplicatasAReceber[]> =>
  xhr.get('/a-receber/totais/duplicatas', { params })

const getReceberDuplicatas = (
  params: IRequestDefault,
): AxiosPromise<{ duplicatasAReceber: Duplicatas[]; totalPaginas: number }> =>
  xhr.get('/a-receber/duplicatas', { params })

const chequesRecebidosTotais = (
  params: IDataAndLoja,
): AxiosPromise<TotaisDuplicatasAReceber[]> =>
  xhr.get('/recebidos/totais/cheques', { params })

const getChequesRecebidos = (
  params: IRequestDefault,
): AxiosPromise<{
  chequesRecebidos: Cheques[]
  totalPaginas: number
}> => xhr.get('/recebidos/cheques', { params })

const getContasPagar = (
  params: IRequestDefault,
): AxiosPromise<{
  contasPagar: ContasPagar[]
  totalPaginas: number
}> => xhr.get('/recebidos/contas-a-pagar', { params })

const contasPagarTotais = (
  params: IDataAndLoja,
): AxiosPromise<TotaisDuplicatasAReceber[]> =>
  xhr.get('/recebidos/totais/contas-a-pagar', { params })

/**
 * Comercial
 */

const getOperacoesComerciais = (
  params: IRequestDefault,
): AxiosPromise<{
  operacoesComerciais: operacoesComerciais[]
  totalPaginas: number
}> => xhr.get('/comercial/operacoes', { params })

const operacoesComerciaisTotais = (
  params: IDataAndLoja,
): AxiosPromise<TotaisDuplicatasAReceber[]> =>
  xhr.get('/comercial/operacoes/totais', { params })

const getGraficoOperacoesComerciais = (
  params: IQueryOperacoesComerciais,
): AxiosPromise<dadosGraficoOperacoesComerciais> =>
  xhr.get('/comercial/operacoes/grafico', { params })

const getFechamentoDeContas = (
  params: IRequestDefault,
): AxiosPromise<{
  fechamentoContas: fechamentoContas[]
  totalPaginas: number
}> => xhr.get('/comercial/fechamento-de-contas', { params })

/**
 * Expedição
 */

const getTransferenciasDeMercadorias = (
  params: IRequestDefault,
): AxiosPromise<{
  transferenciasDeMercadorias: transferenciasDeMercadorias[]
  totalPaginas: number
}> => xhr.get('/expedicao/transferencias-de-mercadorias', { params })

const getGestaoDeEntregas = (
  params: IRequestDefault,
): AxiosPromise<{
  gestaoDeEntregas: gestaoDeEntregas[]
  totalPaginas: number
}> => xhr.get('/expedicao/gestao-de-entregas', { params })

/**
 * Compras
 */

const getPedidosDeCompras = (
  params: IRequestDefault,
): AxiosPromise<{
  pedidosDeCompras: pedidosDeCompras[]
  totalPaginas: number
}> => xhr.get('/compras/pedidos-de-compras', { params })

const getNotasDeEntrada = (
  params: IRequestDefault,
): AxiosPromise<{
  notasDeEntrada: notasDeEntrada[]
  totalPaginas: number
}> => xhr.get('/compras/notas-de-entrada', { params })

const getFornecedoresComprasFilter = (): AxiosPromise<fornecedoresFilter[]> =>
  xhr.get('/compras/fornecedores')

const getCotacoesDeCompras = (
  params: IRequestDefault,
): AxiosPromise<{
  cotacoesDeCompras: cotacoesDeCompras[]
  totalPaginas: number
}> => xhr.get('/compras/cotacoes-de-compras', { params })

/**
 * Venda
 */

const getMovimentoVendas = (
  params: IDataAndLoja,
): AxiosPromise<ResponseMovimentoVendas> =>
  xhr.get('/vendas/movimentos', { params, timeout: 10000 })

const getTotaisLojasVendas = (
  params: IDatas,
): AxiosPromise<ResponseTotaisLojas[]> =>
  xhr.get('/vendas/totais-lojas', { params })

const getTotaisFormasPagamento = (
  params: IDataAndLoja,
): AxiosPromise<ResponseTotaisFormaPagamento[]> =>
  xhr.get('/vendas/totais-forma-pagamento', { params })

/**
 * Produtos
 */

const getCadastroDeProdutos = (
  params: IRequestDefault,
): AxiosPromise<{
  cadastroDeProdutos: cadastroDeProdutos[]
  totalPaginas: number
}> => xhr.get('/produtos/cadastro-de-produtos', { params })

const getEmbalagensDeProdutos = (
  params: IRequestDefault,
): AxiosPromise<{
  embalagensDeProdutos: embalagensDeProdutos[]
  totalPaginas: number
}> => xhr.get('/produtos/embalagens-de-produtos', { params })

const getGruposDeProdutos = (
  params: IRequestDefault,
): AxiosPromise<{
  gruposDeProdutos: gruposDeProdutos[]
  totalPaginas: number
}> => xhr.get('/produtos/grupos-de-produtos', { params })

const getDepartamentosDeProdutos = (
  params: IRequestDefault,
): AxiosPromise<{
  departamentosDeProdutos: departamentosDeProdutos[]
  totalPaginas: number
}> => xhr.get('/produtos/departamentos-de-produtos', { params })

const getCategoriasDeProdutos = (
  params: IRequestDefault,
): AxiosPromise<{
  categoriasDeProdutos: categoriasDeProdutos[]
  totalPaginas: number
}> => xhr.get('/produtos/categorias-de-produtos', { params })

const getFabricantes = (
  params: IRequestDefault,
): AxiosPromise<{
  fabricantes: fabricantes[]
  totalPaginas: number
}> => xhr.get('/produtos/fabricantes', { params })

const getGrupoDeProdutosFilter = (): AxiosPromise<grupoDeProdutos[]> =>
  xhr.get('/produtos/grupo-de-produtos-filter')

const getFabricantesFilter = (): AxiosPromise<fabricantes[]> =>
  xhr.get('/produtos/fabricantes-filter')

const getDepartamentosFilter = (): AxiosPromise<departamentos[]> =>
  xhr.get('/produtos/departamentos-filter')

/**
 * Fiscal
 */

const getNotasFiscais = (
  params: IRequestDefault,
): AxiosPromise<{
  notasFicais: notasFiscais[]
  totalPaginas: number
}> => xhr.get('/fiscal/notas-fiscais', { params })

const notasFiscaisTotais = (
  params: IDataAndLoja,
): AxiosPromise<totaisNotasFiscais[]> =>
  xhr.get('/fiscal/notas-fiscais/totais', { params })

const getCuponsFiscaisEletronicos = (
  params: IRequestDefault,
): AxiosPromise<{
  cuponsFiscaisEletronicos: cuponsFiscaisEletronicosEmitidos[]
  totalPaginas: number
}> => xhr.get('/fiscal/cupons-fiscais-eletronicos', { params })

const cuponsFiscaisEletronicosTotais = (
  params: IDataAndLoja,
): AxiosPromise<totaisCuponsFiscaisEletronicosEmitidos[]> =>
  xhr.get('/fiscal/cupons-fiscais-eletronicos/totais', { params })

const getClientesConstantes = (
  params: IRequestDefault,
): AxiosPromise<{
  clientesConstantes: clientesConstantes[]
  totalPaginas: number
}> => xhr.get('/cadastros/clientes-constantes', { params })

const getClientesEsporadicos = (
  params: IRequestDefault,
): AxiosPromise<{
  clientesEsporadicos: clientesEsporadicos[]
  totalPaginas: number
}> => xhr.get('/cadastros/clientes-esporadicos', { params })

const getUnidadedeNegocios = (
  params: IRequestDefault,
): AxiosPromise<{
  unidadeDeNegocios: unidadeDeNegocios[]
  totalPaginas: number
}> => xhr.get('/cadastros/unidade-de-negocios', { params })

const getDetalhesLoja = (params: {
  numeroLoja: string
}): AxiosPromise<{ unidadeDeNegocios: unidadeDeNegocios[] }> =>
  xhr.get(`/cadastros/unidade-de-negocios`, { params })

const getOrcamentosNaoConvertidos = (
  params: IRequestDefault,
): AxiosPromise<{
  orcamentosNaoConvertidos: orcamentosNaoConvertidos[]
  totalPaginas: number
}> => xhr.get('/comercial/orcamentos-nao-convertidos', { params })

const orcamentosNaoConvertidosTotais = (
  params: IDataAndLoja,
): AxiosPromise<TotaisDuplicatasAReceber[]> =>
  xhr.get('/comercial/orcamentos-nao-convertidos/totais', { params })

const getFollowupsDeOperacoes = (
  params: IRequestDefault,
): AxiosPromise<{
  followupsDeOperacoes: followupsDeOperacoes[]
  totalPaginas: number
}> => xhr.get('/comercial/followups-de-operacoes', { params })

/**
 * Cadastros
 */
const getCentroDeCustos = (
  params: IRequestDefault,
): AxiosPromise<{
  centroDeCustos: centroDeCustos[]
  totalPaginas: number
}> => xhr.get('/cadastros/centro-de-custos', { params })

const getRepresentantes = (
  params: IRequestDefault,
): AxiosPromise<{
  representantes: representantes[]
  totalPaginas: number
}> => xhr.get('/cadastros/representantes', { params })

const getRepresentantesFilter = (): AxiosPromise<representantesFilter[]> =>
  xhr.get('/comercial/representantes')

const getTransportadoras = (
  params: IRequestDefault,
): AxiosPromise<{
  transportadoras: transportadoras[]
  totalPaginas: number
}> => xhr.get('/cadastros/transportadoras', { params })

const getFornecedores = (
  params: IRequestDefault,
): AxiosPromise<{
  fornecedores: fornecedores[]
  totalPaginas: number
}> => xhr.get('/cadastros/fornecedores', { params })

const getFornecedoresFilter = (): AxiosPromise<fornecedoresFilter[]> =>
  xhr.get('/comercial/fornecedores')

const getParceirosIndicantes = (
  params: IRequestDefault,
): AxiosPromise<{
  parceirosIndicantes: parceirosIndicantes[]
  totalPaginas: number
}> => xhr.get('/cadastros/parceiros-indicantes', { params })

const getMarketplaces = (
  params: IRequestDefault,
): AxiosPromise<{
  marketplaces: marketplaces[]
  totalPaginas: number
}> => xhr.get('/cadastros/marketplaces', { params })

const getRamosDeAtividade = (
  params: IRequestDefault,
): AxiosPromise<{
  ramosDeAtividade: ramosDeAtividade[]
  totalPaginas: number
}> => xhr.get('/cadastros/ramos-de-atividade', { params })

const getCondicoesPagamento = (
  params: IRequestDefault,
): AxiosPromise<{
  condicoesPagamento: condicoesPagamento[]
  totalPaginas: number
}> => xhr.get('/cadastros/condicoes-de-pagamento', { params })

const getAdministradoraCartoes = (
  params: IRequestDefault,
): AxiosPromise<{
  administradoraCartoes: administradoraCartoes[]
  totalPaginas: number
}> => xhr.get('/cadastros/administradora-de-cartoes', { params })

const getClassificacaoReceitasDespesas = (
  params: IRequestDefault,
): AxiosPromise<{
  classificacaoReceitasDespesas: classificacaoReceitasDespesas[]
  totalPaginas: number
}> => xhr.get('/cadastros/classificacao-receitas-despesas', { params })

const getPlanoDeContasEmGeral = (
  params: IRequestDefault,
): AxiosPromise<{
  planoDeContasEmGeral: planoDeContasEmGeral[]
  totalPaginas: number
}> => xhr.get('/cadastros/plano-de-contas-em-geral', { params })

const getTabelaDespesas = (
  params: IRequestDefault,
): AxiosPromise<{
  tabelaDespesas: tabelaDespesas[]
  totalPaginas: number
}> => xhr.get('/cadastros/tabela-despesas', { params })

const getTabelaReceitas = (
  params: IRequestDefault,
): AxiosPromise<{
  tabelaReceitas: tabelaReceitas[]
  totalPaginas: number
}> => xhr.get('/cadastros/tabela-receitas', { params })

const getTableRegioes = (
  params: IRequestDefault,
): AxiosPromise<{
  regioes: regioes[]
  totalPaginas: number
}> => xhr.get('/cadastros/tabela-regioes', { params })

const getTableCidades = (
  params: IRequestDefault,
): AxiosPromise<{
  cidades: cidades[]
  totalPaginas: number
}> => xhr.get('/cadastros/tabela-cidades', { params })

const getTablePaises = (
  params: IRequestDefault,
): AxiosPromise<{
  paises: paises[]
  totalPaginas: number
}> => xhr.get('/cadastros/tabela-paises', { params })

const getTableCadastroDeDolares = (
  params: IRequestDefault,
): AxiosPromise<{
  cadastroDeDolares: cadastroDeDolares[]
  totalPaginas: number
}> => xhr.get('/cadastros/cadastro-de-dolares', { params })

const getTableFeriados = (
  params: IRequestDefault,
): AxiosPromise<{
  feriados: feriados[]
  totalPaginas: number
}> => xhr.get('/cadastros/feriados', { params })
const getBandeirasDeCartoes = (
  params: IRequestDefault,
): AxiosPromise<{
  bandeirasDeCartoes: bandeirasDeCartoes[]
  totalPaginas: number
}> => xhr.get('/cadastros/bandeiras-de-cartoes', { params })

const getContasDeMovimentacaoFinanceira = (
  params: IRequestDefault,
): AxiosPromise<{
  contasDeMovimentacaoFinanceira: contasDeMovimentacaoFinanceira[]
  totalPaginas: number
}> => xhr.get('/cadastros/contas-de-movimentacao-financeira', { params })

/**
 * Demonstrativos
 */

const allEquipes = (): AxiosPromise<IResEquipes[]> => xhr.get('/equipes')

const getCarteiraClientes = (
  params: IFilters,
): AxiosPromise<IResCarteiraCliente> =>
  xhr.get('/vendas/carteira-clientes', { params })

const getCarteiraClientesByEquipe = (
  params: IFilters,
): AxiosPromise<IResCarteiraClientesByEquipe> =>
  xhr.get('/vendas/carteira-clientes-equipe', { params })

const getCategoriaLucro = (): AxiosPromise<any> =>
  xhr.get('/vendas/categoria-lucro')

const workinAndWorkedDays = (
  params: IReqWorkinAndWorkedDays,
): AxiosPromise<IResWorkinAndWorkedDays> =>
  xhr.get('/dias-uteis-e-trabalhados', { params })

const totaisEquipeRanking = (
  params: IFilters,
): AxiosPromise<IResTotaisEquipesRanking> =>
  xhr.get('/vendas/totais-equipes-ranking', { params })

const totaisVendedores = (
  params: IFilters,
): AxiosPromise<IResTotaisVendedores> =>
  xhr.get('/vendas/totais-vendedores', { params })

const topVendas = (params: IRequestTopVendas): AxiosPromise<TopVendas[]> =>
  xhr.get('/top-vendas', { params })

const getComparativoDeVendas = (
  params: IRequestComparativoVendas,
): AxiosPromise<ComparativoVendas[]> =>
  xhr.get('/vendas/comparativo', { params })

const getClientesByHistoricoDeCompra = (
  params: IRequestGetClientesByHistoricoDeCompra,
): AxiosPromise<ClientesByHistoricoDeCompra[]> =>
  xhr.get('/clientes/historico-compra', { params })

const getComprasUltimoAnoCliente = (params: {
  clientId: number
  numeroLoja?: number
}): AxiosPromise<ComprasUltimoAnoCliente[]> =>
  xhr.get('/clientes/compras-ultimo-ano', { params })

const getEmailByCodigo = (codigo: number | string): AxiosPromise<EmailById> =>
  xhr.get(`/cliente/email/${codigo}`)

export default {
  login,
  getConfiguracaoCnx,
  getConfiguracoesForcaVendasLoja,
  getOperacoesNaoRecebidas,
  getCaixasAgrupadosECaixas,
  getTotalizersCashiers,
  getValuesMasterCashiers,
  getValuesStoreCashiers,
  getValoresCaixasPorFormaPagamento,
  getValoresCaixasPorFormaPagamentoECodigo,
  getLojas,
  getLojasLogin,
  getDiasFuncionamento,
  getOperacoesCaixa,
  getProdutos,
  totaisPorStatusOperacaoNaoRecebida,
  buscarRamosAtividade,
  editarRamoAtividade,
  excluirRamoAtividade,
  adicionarRamoAtividade,
  totaisPorTipoOperacao,
  buscarPedidoPeloCodigo,
  getItensPedido,
  getItensPedidoFCO,
  getPedidoEItens,
  getPedidoEItensFCO,
  getTotaisCartoes,
  getTotaisCartoesPorAdministradora,
  getTotaisCartoesPorBandeiras,
  getTotaisCartoesPorLojas,
  getTotaisCartoesPorModalidades,
  getDetalhesCartoes,
  getTotaisDetalhamentoCartoes,
  getOptionsBandeiraCartoes,
  getOptionsAdministradoraCartoes,
  getRankingTaxasCartoes,
  aReceberTotaisDuplicatas,
  getReceberDuplicatas,
  chequesRecebidosTotais,
  getChequesRecebidos,
  getContasPagar,
  contasPagarTotais,
  getOperacoesComerciais,
  operacoesComerciaisTotais,
  getGraficoOperacoesComerciais,
  getPedidosDeCompras,
  getFechamentoDeContas,
  // vandas: {  Fazer isso no futuro
  //   getMovimentoVendas,
  //   getTotaisLojasVendas,
  //   getTotaisFormasPagamento,
  // },
  getMovimentoVendas,
  getTotaisLojasVendas,
  vendas: {
    getTotaisFormasPagamento,
  },
  topVendas,
  getCadastroDeProdutos,
  getNotasFiscais,
  notasFiscaisTotais,
  getCuponsFiscaisEletronicos,
  cuponsFiscaisEletronicosTotais,
  getClientesConstantes,
  getClientesEsporadicos,
  getUnidadedeNegocios,
  getCentroDeCustos,
  getRepresentantes,
  getTransportadoras,
  getFornecedores,
  getParceirosIndicantes,
  getMarketplaces,
  getRamosDeAtividade,
  getCondicoesPagamento,
  getAdministradoraCartoes,
  getClassificacaoReceitasDespesas,
  getPlanoDeContasEmGeral,
  getTabelaDespesas,
  getTabelaReceitas,
  getTableRegioes,
  getTableCidades,
  getTablePaises,
  getTableCadastroDeDolares,
  getTableFeriados,
  getComparativoDeVendas,
  getBandeirasDeCartoes,
  getContasDeMovimentacaoFinanceira,
  getOrcamentosNaoConvertidos,
  orcamentosNaoConvertidosTotais,
  getFollowupsDeOperacoes,
  getClientesByHistoricoDeCompra,
  getComprasUltimoAnoCliente,
  getFechamentosDeCaixa,
  FechamentosCaixaTotais,
  getGraficoFechamentosCaixa,
  getGrupoDeProdutosFilter,
  getFabricantesFilter,
  getDepartamentosFilter,
  getRepresentantesFilter,
  getFornecedoresFilter,
  getAbatimentoDeContas,
  getEmailByCodigo,
  getGraficoAbatimentoDeContas,
  abatimentoDeContasTotais,
  getLotesDeRecebimento,
  getEmbalagensDeProdutos,
  getGruposDeProdutos,
  getFabricantes,
  getFornecedoresComprasFilter,
  getNotasDeEntrada,
  getCategoriasDeProdutos,
  getDepartamentosDeProdutos,
  getTransferenciasDeMercadorias,
  getDetalhesLoja,
  getCotacoesDeCompras,
  getGestaoDeEntregas,
  totaisEquipeRanking,
  totaisVendedores,
  workinAndWorkedDays,
  getCategoriaLucro,
  getCarteiraClientes,
  getCarteiraClientesByEquipe,
  allEquipes,
}
