import axios, { AxiosRequestConfig, AxiosRequestHeaders } from 'axios'

const url =
  process.env.NODE_ENV === 'development'
    ? 'http://192.168.1.179:3338'
    : 'https://api.csgestor.maxscalla.com.br'

const headers = (tokenAuth: string): AxiosRequestHeaders => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  Authorization: `Bearer ${tokenAuth}`,
})

const getHeaders = (): AxiosRequestHeaders => {
  const tokenAuth = localStorage.getItem('@CSGestor:token')

  return headers(tokenAuth!)
}

const xhr = axios.create({
  baseURL: url,
  headers: getHeaders(),
  // timeout: 100000,
})

export const setTokenAfterLogin = async (tokenAuth: string): Promise<void> => {
  xhr.interceptors.request.use(
    async (config: AxiosRequestConfig) => ({
      ...config,
      headers: headers(tokenAuth),
    }),
    (error) => Promise.reject(error),
  )
}

xhr.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
)

export default xhr
