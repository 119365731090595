const moneyMask = (
  value: number | string,
  removeNegative?: boolean,
): string => {
  const numericValue = Number(value) || 0

  const finalValue = removeNegative ? Math.abs(numericValue) : numericValue

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })

  return formatter.format(finalValue)
}

export default moneyMask
